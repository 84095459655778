import React from "react";
import Layout from "../../components/layout"
import SearchResultPage from "../../templates/search-results-template-holiday-lets";
import SearchSelect from "../../components/SearchSelect/SearchSelect"

const SearchResultResiSales = (props) => {

    const fullpath = props.location.pathname
    const location = props.location
    const fullpathone = props.location
    const [scroll, setScroll] = React.useState(false);

    React.useEffect(() => {
        window.addEventListener("scroll", () => {
            setScroll(window.scrollY > 60)
        })
    }, [])

    return (
        <div className='search-results-page holiday-lets'>
            <Layout isHoliday={true}>
                <section className={scroll ? "search-banner search-banner--fixed" : "search-banner"}>
                    {/* <SearchSelect /> */}
                    <SearchResultPage
                        locationname="/holiday-lets/"
                        pcategorytype="short-lettings"
                        multipcategorytype={['short-lettings']}
                        ptype="lettings"
                        ptypetag="for-sale"
                        pstatustype={['To Rent']}
                        fullpathone={fullpathone}
                        fullpathname={fullpath}
                        {...props}
                        psearch="Lettings"
                    />
                </section>
            </Layout>
        </div>
    );
};

export default SearchResultResiSales;