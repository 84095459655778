import React, { useState, useEffect } from "react";
import {NoResultProperties} from '../../common/queries/common_use_query';
import PropertySlider from '../PropertySlider/PropertySliderNoResult'
import { Col, Container, Row, Form } from "react-bootstrap";
import { numberFormat } from "../../common/site/utils";

const PropertyNoResult = (props) => {
    
    const[department, setDepartment] = useState('residential');
    let status=props.status;

    useEffect(()=>{
        props.department && setDepartment(props.department);
    },[props.department]);

    if(department==="short-lettings"){
        status=["to-let"]
    }
    

    const {loading:loading, error:error, data:data} = NoResultProperties(props.status, department);
    return (
        <React.Fragment>
            {
                data && data.properties.length > 0 &&
                <Row className="no-result-properties">
                    {
                        data.properties.map((hit, index) => {

                            // property details url structure
                            let uriStr = "";
                    
                            if (hit.search_type === "sales" && hit.department === 'residential') {
                                uriStr = `property-for-sale/`
                            } else if (hit.search_type === "lettings" && hit.department === 'residential') {
                                uriStr = `property-to-rent/`
                            }
                            // property details url structure
                            let processedImages = JSON.stringify({});
                            if (hit?.imagetransforms?.images_Transforms) {
                                processedImages = hit.imagetransforms.images_Transforms;
                            }  
                            return (
                                <Col md={6} lg={3}>
                                <PropertySlider 
                                    price={hit.price && hit.price != null ? numberFormat(hit.price) : ''}
                                    propertyid={hit.id}
                                    title={hit.title}
                                    location={hit.display_address}
                                    description={hit.description}
                                    bedroomsCount={hit.bedroom}
                                    bathroomsCount={hit.bathroom}
                                    receptionsCount={hit.reception}
                                    propertyVideo={hit.virtual_tour}
                                    propertyImages={hit.images}
                                    thumb={hit.thumbnail}
                                    propertyDetailsLink={`/${uriStr}${hit.slug}-${hit.id}/`}
                                />
                                </Col>
                            )
                        })
                    }
                </Row>
            }
        </React.Fragment>
    )
}

export default PropertyNoResult;